import { axiosBaseQuery } from '../../hooks'
import { API_ROOT, network } from '../../../constant/network'
import { ResponseData } from '../../../types'
import { createApi } from '@reduxjs/toolkit/query/react'
import { BrandType } from '../../../types/brandType'

export const brandApi = createApi({
  reducerPath: 'brandApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    getBrandList: build.query<BrandType[] | undefined, unknown>({
      query: () => ({
        url: network.brand.getList,
        method: 'get',
      }),
      transformResponse: (rawResult: ResponseData<BrandType[]>) => {
        return rawResult.data
      },
    }),
    getBrand: build.query<BrandType | undefined, unknown>({
      query: (slug: string) => ({
        url: network.brand.getItem(slug),
        method: 'get',
      }),
      transformResponse: (rawResult: ResponseData<BrandType>) => {
        return rawResult.data
      },
    }),
  }),
})

export const { useGetBrandListQuery, useGetBrandQuery } = brandApi

export default brandApi
