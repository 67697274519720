import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { UserNotifyItemType } from '../../../types/userNotify'

export const slice = createSlice({
  name: 'userNotifyScreen',
  initialState: {
    notifyTabData: [] as UserNotifyItemType[],
  },
  reducers: {
    changeNotifyTabData: (state, action) => {
      state.notifyTabData = action.payload
    },
  },
})

export const selectNotifiTabData = (state: RootState) => state[slice.name].notifyTabData

export const { changeNotifyTabData } = slice.actions

export const UserNotifyScreenReducer = slice.reducer
