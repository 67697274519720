import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { axiosBaseQuery } from '../../hooks'
import { API_ROOT, network } from '../../../constant/network'
import { ResponseData } from '../../../types'
import {
  DeleteItemCartDtoType,
  ReOrderDto,
  ResponseCartType,
  SelectedAllDtoType,
  SelectedAllType,
  UpDateAddItemCartDtoType,
} from '../../../types/cartType'

const cartApi = createApi({
  reducerPath: 'cartApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  tagTypes: ['Cart'],
  endpoints: (build) => ({
    getCart: build.query<ResponseCartType | undefined, unknown>({
      query: () => ({
        url: network.cart.getCart,
        method: 'GET',
      }),
      providesTags: ['Cart'],
      transformResponse: (rawResult: ResponseData<ResponseCartType>) => {
        return rawResult?.data
      },
    }),
    updateItem: build.mutation<[] | undefined, unknown>({
      query: (params: UpDateAddItemCartDtoType) => ({
        url: network.cart.updateItem,
        method: 'POST',
        data: params,
      }),
      invalidatesTags: ['Cart'],
    }),
    addItem: build.mutation<[] | undefined, unknown>({
      query: (params: UpDateAddItemCartDtoType) => ({
        url: network.cart.addItem,
        method: 'POST',
        data: params,
      }),
      invalidatesTags: ['Cart'],
    }),
    deleteItem: build.mutation<[] | undefined, unknown>({
      query: (params: DeleteItemCartDtoType) => ({
        url: network.cart.deleteItem,
        method: 'POST',
        data: params,
      }),
      invalidatesTags: ['Cart'],
    }),
    selectedAllItem: build.mutation<SelectedAllType | undefined, unknown>({
      query: (params: SelectedAllDtoType) => ({
        url: network.cart.selectedAll,
        method: 'POST',
        data: params,
      }),
      invalidatesTags: ['Cart'],
    }),
    reOrder: build.mutation({
      query: (params: ReOrderDto) => ({
        url: network.cart.reOrder,
        method: 'POST',
        data: params,
      }),
      invalidatesTags: ['Cart'],
    }),
  }),
})

export const {
  useGetCartQuery,
  useAddItemMutation,
  useDeleteItemMutation,
  useUpdateItemMutation,
  useSelectedAllItemMutation,
  useReOrderMutation,
} = cartApi

export default cartApi
