import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { BaseReview, ProductReview, ReviewSuggest } from '../../../types/orderReiew'

export const slice = createSlice({
  name: 'orderReviewDetailPage',
  initialState: {
    productReviewList: [] as ProductReview[],
    shippingReview: [] as BaseReview[],
    consultReview: [] as BaseReview[],
    productReviewSuggest: [] as ReviewSuggest[],
    shippingReviewSuggest: [] as ReviewSuggest[],
    consultReviewSuggest: [] as ReviewSuggest[],
  },
  reducers: {
    changeProductReview: (state, action) => {
      state.productReviewList = action.payload
    },
    changeShippingReview: (state, action) => {
      state.shippingReview = action.payload
    },
    changeConsultReview: (state, action) => {
      state.consultReview = action.payload
    },
    changeProductReviewSuggest: (state, action) => {
      state.productReviewSuggest = action.payload
    },
    changeShippingReviewSuggest: (state, action) => {
      state.shippingReviewSuggest = action.payload
    },
    changeConsultReviewSuggest: (state, action) => {
      state.consultReviewSuggest = action.payload
    },
  },
})

export const orderReviewStore = (state: RootState) => state[slice.name]

export const {
  changeProductReview,
  changeShippingReview,
  changeConsultReview,
  changeProductReviewSuggest,
  changeShippingReviewSuggest,
  changeConsultReviewSuggest,
} = slice.actions

export const OrderReviewDetailReducer = slice.reducer
