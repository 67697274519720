import { axiosBaseQuery } from '../../hooks'
import { API_ROOT, network } from '../../../constant/network'
import { ResponseData } from '../../../types'
import { createApi } from '@reduxjs/toolkit/query/react'
import {
  PromotionType,
  SaveVoucherDto,
  SaveVoucherResType,
  VoucherGroupDto,
  VoucherGroupType,
} from '../../../types/voucherType'
import { CartType } from '../../../types/cartType'

export const voucherApi = createApi({
  reducerPath: 'voucherApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  tagTypes: ['Cart', 'VoucherGroup'],
  endpoints: (build) => ({
    getVoucherList: build.query<PromotionType[] | undefined, unknown>({
      query: (params) => ({
        url: network.voucher.getList,
        method: 'get',
        params,
      }),
      transformResponse: (rawResult: ResponseData<PromotionType[]>) => {
        return rawResult.data
      },
    }),
    getVoucher: build.query<PromotionType | undefined, unknown>({
      query: (slug: string) => ({
        url: network.voucher.getItem(slug),
        method: 'get',
      }),
      transformResponse: (rawResult: ResponseData<PromotionType>) => {
        return rawResult.data
      },
    }),
    updateCartPromotion: build.mutation<[] | undefined, unknown>({
      query: (params: CartType) => ({
        url: network.cart.updatePromotion,
        method: 'POST',
        data: params,
      }),
      invalidatesTags: ['Cart'],
    }),
    getListVoucherGroup: build.query<VoucherGroupType[] | undefined, unknown>({
      query: (params: VoucherGroupDto) => ({
        url: network.voucher.getListGroup,
        method: 'GET',
        params: params,
      }),
      providesTags: ['VoucherGroup'],
      transformResponse: (raw: ResponseData<VoucherGroupType[]>) => raw.data,
    }),
    saveVoucher: build.mutation<SaveVoucherResType | undefined, unknown>({
      query: (params: SaveVoucherDto) => ({
        url: network.voucher.saveVoucher,
        method: 'POST',
        data: params,
      }),
      invalidatesTags: ['VoucherGroup'],
    }),
  }),
})

export const {
  useGetVoucherListQuery,
  useGetVoucherQuery,
  useUpdateCartPromotionMutation,
  useGetListVoucherGroupQuery,
  useSaveVoucherMutation,
} = voucherApi

export default voucherApi
