import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

export const slice = createSlice({
  name: 'pointPage',
  initialState: {
    currentPage: 1,
    lastPage: 1,
  },
  reducers: {
    changeCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    changeLastPage: (state, action) => {
      state.lastPage = action.payload
    },
  },
})

export const selectCurrentPage = (state: RootState) => state[slice.name].currentPage
export const selectLastPage = (state: RootState) => state[slice.name].lastPage

export const { changeCurrentPage, changeLastPage } = slice.actions

export const PointPageReducer = slice.reducer
