import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store'
import { language, languageJA, languageVI } from '../constant'
import { UserType } from '../types'

export const slice = createSlice({
  name: 'store',
  initialState: {
    isLogined: false,
    isNavOpen: false,
    user: undefined as UserType | undefined,
    lang: language.vi.id,
  },
  reducers: {
    changeIsLogin: (state, action) => {
      state.isLogined = action.payload
    },
    changeUser: (state, action) => {
      state.user = action.payload
    },
    changeIsNavOpen: (state, action) => {
      state.isNavOpen = action.payload
    },
    changeLang: (state, action) => {
      const lang = action.payload
      state.lang = lang
      localStorage.setItem('languageOmiPharma', lang)
      document.documentElement.lang = lang === languageJA ? 'ja' : languageVI
    },
  },
})

export const selectIslogin = (state: RootState) => state[slice.name].isLogined
export const selectUser = (state: RootState) => state[slice.name].user
export const selectIsNavOpen = (state: RootState) => state[slice.name].isNavOpen
export const selectLang = (state: RootState) => state[slice.name].lang

export const { changeIsLogin, changeUser, changeIsNavOpen, changeLang } = slice.actions

export const StoreReducer = slice.reducer
