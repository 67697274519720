import { axiosBaseQuery } from '../../../hooks'
import { API_ROOT, network } from '../../../../constant/network'
import { ResponseData } from '../../../../types'
import { createApi } from '@reduxjs/toolkit/query/react'
import {
  AddItemToWishListDtoType,
  DeleteAllItemFromWishListDtoType,
  DeleteItemFromWishListDtoType,
  WishListItemDetailType,
  WishListItemDtoType,
  WishListItemType,
} from '../../../../types/wishListType'

const userWishListApi = createApi({
  reducerPath: 'userWishListApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  tagTypes: ['WishList'],
  endpoints: (build) => ({
    getListWishList: build.query<Array<WishListItemType> | undefined, unknown>({
      query: () => ({
        url: network.userProductWishList.getList,
        method: 'GET',
      }),
      providesTags: ['WishList'],
      transformResponse: (rawResult: ResponseData<WishListItemType[]>) => {
        return rawResult.data
      },
    }),
    getWishList: build.query<WishListItemDetailType | undefined, unknown>({
      query: (id: string | number) => ({
        url: network.userProductWishList.getItem + id,
        method: 'GET',
      }),
      providesTags: ['WishList'],
      transformResponse: (rawResult: ResponseData<WishListItemDetailType>) => {
        return rawResult.data
      },
    }),
    addWishList: build.mutation<WishListItemType | undefined, unknown>({
      query: (params: WishListItemDtoType) => ({
        url: network.userProductWishList.addItem,
        method: 'POST',
        data: params,
      }),
      invalidatesTags: ['WishList'],
      transformResponse: (rawResult: ResponseData<WishListItemType>) => {
        return rawResult.data
      },
    }),
    updateWishList: build.mutation<boolean | undefined, unknown>({
      query: (params: WishListItemDtoType) => ({
        url: network.userProductWishList.updateItem + params?.id,
        method: 'put',
        data: params,
      }),
      invalidatesTags: ['WishList'],
      transformResponse: (rawResult: ResponseData<boolean>) => {
        return rawResult.data
      },
    }),
    deleteWishList: build.mutation<boolean | undefined, unknown>({
      query: (id: string | number) => ({
        url: network.userProductWishList.deleteItem + id,
        method: 'delete',
      }),
      invalidatesTags: ['WishList'],
      transformResponse: (rawResult: ResponseData<boolean>) => {
        return rawResult.data
      },
    }),

    addToWishList: build.mutation<[] | undefined, unknown>({
      query: (params: AddItemToWishListDtoType) => ({
        url: network.userProductWishList.addToItem,
        method: 'post',
        data: params,
      }),
      invalidatesTags: ['WishList'],
    }),

    deleteItemFromWishList: build.mutation<number | undefined, unknown>({
      query: (params: DeleteItemFromWishListDtoType) => ({
        url: network.userProductWishList.deleteFromItem,
        method: 'post',
        data: params,
      }),
      invalidatesTags: ['WishList'],
    }),

    deleteAllFromWishList: build.mutation<number | undefined, unknown>({
      query: (params: DeleteAllItemFromWishListDtoType) => ({
        url: network.userProductWishList.deleteAllFromItem,
        method: 'post',
        data: params,
      }),
      invalidatesTags: ['WishList'],
      transformResponse: (rawResult: ResponseData<number>) => {
        return rawResult.data
      },
    }),
  }),
})

export const {
  useGetListWishListQuery,
  useGetWishListQuery,
  useAddWishListMutation,
  useDeleteWishListMutation,
  useUpdateWishListMutation,
  useAddToWishListMutation,
  useDeleteAllFromWishListMutation,
  useDeleteItemFromWishListMutation,
} = userWishListApi

export default userWishListApi
