import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { AddressItemType } from '../../../types/userAddressType'

export const slice = createSlice({
  name: 'addressDetail',
  initialState: {
    data: {} as AddressItemType,
  },
  reducers: {
    changeData: (state, action) => {
      state.data = action.payload
    },
  },
})

export const selectData = (state: RootState) => state[slice.name].data

export const { changeData } = slice.actions

export const AddressDetailReducer = slice.reducer
