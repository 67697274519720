import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { API_ROOT, network } from '../../../constant/network'
import { ResponseData } from '../../../types'
import { SearchDrugDto, DrugType, DrugHistorySearchType } from '../../../types/searchDug'
import { axiosBaseQuery } from '../../hooks'

const searchDugApi = createApi({
  reducerPath: 'searchDugApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    searchDug: build.mutation<ResponseData<DrugType[]> | undefined, unknown>({
      query: (params: SearchDrugDto) => ({
        url: network.searchDug.search,
        method: 'POST',
        data: params,
      }),
    }),
    getHistory: build.query<DrugHistorySearchType[] | undefined, unknown>({
      query: () => ({
        url: network.searchDug.historySearch,
        method: 'GET',
      }),
      transformResponse: (res: ResponseData<DrugHistorySearchType[]>) => res.data,
    }),
  }),
})

export const { useSearchDugMutation, useGetHistoryQuery } = searchDugApi

export default searchDugApi
