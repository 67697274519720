import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ROOT, network } from '../../../../constant/network'
import { ResponseData, ResponseDataNotify } from '../../../../types'
import { GetNotifyListDto, UserNotifyItemType } from '../../../../types/userNotify'
import { axiosBaseQuery } from '../../../hooks'

const userNotifyApi = createApi({
  reducerPath: 'userNotifyApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  tagTypes: ['Notify'],
  endpoints: (build) => ({
    getNotifyList: build.query<UserNotifyItemType[] | undefined, unknown>({
      query: () => ({
        url: network.userNotify.getList,
        method: 'GET',
      }),
      providesTags: ['Notify'],
      transformResponse: (rawResult: ResponseData<UserNotifyItemType[]>) => {
        return rawResult.data
      },
    }),
    getCertainNotifyList: build.mutation<ResponseDataNotify<UserNotifyItemType[]> | undefined, unknown>({
      query: (params: GetNotifyListDto) => ({
        url: network.userNotify.getCertainList,
        method: 'POST',
        data: params,
      }),
    }),
    getNotifyDetail: build.query<UserNotifyItemType | undefined, unknown>({
      query: (id: number) => ({
        url: network.userNotify.getItem + id,
        method: 'GET',
      }),
      providesTags: ['Notify'],
      transformResponse: (rawResult: ResponseData<UserNotifyItemType>) => {
        return rawResult.data
      },
    }),
    readAllNotify: build.mutation({
      query: () => ({
        url: network.userNotify.readAll,
        method: 'POST',
      }),
      invalidatesTags: ['Notify'],
    }),
    deleteNotify: build.mutation<boolean | undefined, unknown>({
      query: (id: number) => ({
        url: network.userNotify.deleteItem + id,
        method: 'DELETE',
      }),
      transformResponse: (rawResult: ResponseData<boolean>) => {
        return rawResult.data
      },
      invalidatesTags: ['Notify'],
    }),
    countUnreadTotal: build.mutation<any | undefined, unknown>({
      query: () => ({
        url: network.userNotify.countUnreadTotal,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetNotifyListQuery,
  useDeleteNotifyMutation,
  useLazyGetNotifyDetailQuery,
  useReadAllNotifyMutation,
  useGetCertainNotifyListMutation,
  useCountUnreadTotalMutation,
} = userNotifyApi
export default userNotifyApi
