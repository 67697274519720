import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ROOT, network } from '../../../constant/network'
import { ResponseData } from '../../../types'
import { categoryType } from '../../../types/category'
import { axiosBaseQuery } from '../../hooks'

const categoryApi = createApi({
  reducerPath: 'categoryApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    getListCategory: build.query<categoryType[] | undefined, unknown>({
      query: () => ({
        url: network.category.getList,
        method: 'GET',
      }),
      transformResponse: (rawData: ResponseData<categoryType[]>) => {
        return rawData.data
      },
    }),
    getCategoryBySlug: build.query<categoryType | undefined, unknown>({
      query: (params: { slug: string; type?: number }) => ({
        url: params.slug ? network.category.getList + '/' + params.slug : network.category.getList,
        method: 'GET',
        params: {
          type: params.type,
        },
      }),
      transformResponse: (rawData: ResponseData<categoryType>) => {
        return rawData.data
      },
    }),
    searchCategory: build.query<categoryType[] | undefined, unknown>({
      query: (param) => ({
        url: network.category.searchCategory,
        method: 'POST',
        data: param,
      }),
      transformResponse: (rawData: ResponseData<categoryType[]>) => {
        return rawData.data
      },
    }),
  }),
})

export const { useGetListCategoryQuery, useGetCategoryBySlugQuery, useSearchCategoryQuery } = categoryApi

export default categoryApi
