import { axiosBaseQuery } from '../../hooks'
import { API_ROOT, network } from '../../../constant/network'
import { createApi } from '@reduxjs/toolkit/query/react'
import { ResponseData } from '../../../types'

export const customerApi = createApi({
  reducerPath: 'customerApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    updateCustomer: build.mutation<ResponseData<boolean> | undefined, unknown>({
      query: (params: any) => ({
        url: network.customer.updateCustomer + params.customer_id,
        method: 'PUT',
        data: params.params,
      }),
    }),
  }),
})

export const { useUpdateCustomerMutation } = customerApi

export default customerApi
