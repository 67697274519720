import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ROOT, network } from '../../../constant/network'
import { ResponseData } from '../../../types'
import { SearchComment, commentType } from '../../../types/postType'
import { axiosBaseQuery } from '../../hooks'

export const commentApi = createApi({
  reducerPath: 'commentAPi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    pushComment: build.mutation<ResponseData<commentType>, unknown>({
      query: (data) => ({
        url: network.comment.comment,
        method: 'post',
        data: data,
      }),
      transformResponse: (rawResult: ResponseData<commentType>) => {
        return rawResult
      },
    }),
    getComment: build.mutation<ResponseData<commentType[]>, unknown>({
      query: (data: SearchComment) => ({
        url: network.comment.search,
        method: 'post',
        data: data,
      }),
      transformResponse: (rawResult: ResponseData<commentType[]>) => {
        return rawResult
      },
    }),
  }),
})

export const { usePushCommentMutation, useGetCommentMutation } = commentApi

export default commentApi
