import { useState } from 'react'
import '../assets/css/alert.scss'
import svgAlertSuccess from '../assets/svg/alert_success.svg'
import svgAlertWarning from '../assets/svg/alert_warning.svg'
import IconClose from '../assets/img/icon-close.png'
import { Link } from 'react-router-dom'
import { ROUTER } from '../router/config'
import { Trans, useTranslation } from 'react-i18next'
import { LinkText } from './common/LinkText'

declare global {
  interface Window {
    alertSuccess: (message: string, onDone: any, title: string) => void
    alertWarning: (message: string, onDone: any, title: string) => void
    alertLogin: (message: string) => void
    popupImage: (imgPopup: string) => void
  }
}

interface State {
  type?: 'alert' | 'warning' | 'login' | 'image'
  show: boolean
  message?: string
  title?: string
  onDone?: any
  imgPopup?: any
}

let setAlertState: any

function preloadImage(url: string) {
  const img = new Image()
  img.src = url
}

preloadImage(svgAlertSuccess)
preloadImage(svgAlertWarning)

export function alertWithCode(res: any, onDone: any = null) {
  if (res.code === 200 || res.code === 0) {
    alertSuccess(res.message, onDone)
  } else {
    alertWarning(res.message, onDone)
  }
}

export function alertSuccess(message: string, onDone: any = null, title = 'Thông báo') {
  if (!setAlertState) {
    console.error('setAlertState not undefined')
    return
  }

  setAlertState({
    type: 'success',
    show: true,
    message,
    title,
    onDone,
  })
}

export function alertWarning(message: string, onDone: any = null, title = 'Thông báo') {
  if (!setAlertState) {
    console.error('setAlertState not undefined')
    return
  }

  setAlertState({
    type: 'warning',
    show: true,
    message,
    title,
    onDone,
  })
}

export function alertLogin(message: string) {
  if (!setAlertState) {
    console.error('setAlertState not undefined')
    return
  }

  setAlertState({
    type: 'login',
    show: true,
    message,
  })
}

export function popupImage(imgPopup: string) {
  if (!setAlertState) {
    console.error('setAlertState not undefined')
    return
  }

  setAlertState({
    type: 'image',
    show: true,
    imgPopup,
  })
}

export function Alert() {
  const [state, setState] = useState<State>({ show: false, message: '' })
  setAlertState = setState
  const display = state.show ? 'flex' : 'none'
  const hotline = process.env.REACT_APP_HOTLINE

  if (state.type === 'login') {
    return AlertLogin(state, setState, display)
  }

  if (state.type === 'image') {
    return <PopupImage src={state.imgPopup} />
  }

  const icon = state.type === 'warning' ? svgAlertWarning : svgAlertSuccess
  return (
    <div className={'alert-overlay ' + (state.type || '')} style={{ display }}>
      <div className='alert-body'>
        <div className='alert-logo'>
          <img src={icon} alt='' />
        </div>
        <div className='alert-label'>Thông báo</div>
        <div className='alert-content'>
          <div className='alert-content-main' style={{ fontWeight: 500 }}>
            {state.message}
          </div>
          <div className='alert-content-sign'>
            <Trans
              i18nKey='alert.contactHotline'
              components={{
                telLink: <LinkText to='tel:0868680303' title='Tel Link' />,
              }}
              values={{ hotline: hotline }}
            />
          </div>
        </div>

        <div className='alert-footer'>
          <button
            className='alert-close-btn'
            type='button'
            onClick={() => {
              setState({
                show: false,
              })

              if (state.onDone) {
                state.onDone()
              }
            }}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  )
}

function onClose(setState: any) {
  window.location.href = ROUTER.HOME_PAGE
  setState({ show: false })
}

function AlertLogin(state: State, setState: any, display: any) {
  const { t } = useTranslation()
  return (
    <div className={'alert-overlay ' + (state.type || '')} style={{ display }}>
      <div className='alert-body'>
        <span className='alert-close' onClick={() => onClose(setState)}>
          ×
        </span>
        <div className='alert-label'>Thông báo</div>
        <div className='alert-content'>{state.message ? state.message : t('alert.loginToSee')}</div>
        <div className='alert-content mt-2'>
          <a
            // href={routes.login}
            className='btn-alert-login'
          >
            <button className='btn btn-outline-bl'>{t('alert.login')}</button>
          </a>
        </div>
        <div className='alert-content mt-2'>{t('alert.loginWith')}</div>
        <div className='alert-footer' style={{ display: 'flex', justifyContent: 'center' }}>
          <Link to='#' className='social social-facebook'>
            <i className='fab fa-facebook-f' />
          </Link>
          <Link to='#' className='social social-google'>
            <i className='fab fa-google-plus-g' />
          </Link>
        </div>
      </div>
    </div>
  )
}

function PopupImage(props: any) {
  const imgPopup: any = props.src
  const [show, setShow] = useState<boolean>(false)

  if (!imgPopup || !imgPopup.image || !imgPopup.image_mobile) {
    return null
  }

  function onLoadImage() {
    setShow(true)
  }

  return (
    <div
      className='alert-overlay'
      style={{ display: show ? 'flex' : 'none' }}
      onClick={() => {
        setShow(false)
      }}
    >
      <div className='alert-body-image'>
        <a
          className='alert-close'
          onClick={() => {
            setShow(false)
          }}
        >
          <img src={IconClose} alt='' style={{ width: 15, height: 15 }} />
        </a>
        <div className='alert-content'>
          <a href={imgPopup.link}>
            <img src={imgPopup.image_mobile || imgPopup.image} onLoad={onLoadImage} alt='' />
          </a>
        </div>
      </div>
    </div>
  )
}

window.alertSuccess = alertSuccess
window.alertWarning = alertWarning
window.alertLogin = alertLogin
window.popupImage = popupImage
