import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { API_ROOT, network } from '../../../constant/network'
import { ResponseData } from '../../../types'
import {
  RegularScheduleItemType,
  UpdateRegularConfirmItemDto,
  UpdateRegularScheduleItemDto,
} from '../../../types/regularScheduleType'
import { axiosBaseQuery } from '../../hooks'

const regularScheduleApi = createApi({
  reducerPath: 'regularScheduleApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  tagTypes: ['Regular'],
  endpoints: (build) => ({
    getListRegularSchedule: build.query<RegularScheduleItemType[] | undefined, unknown>({
      query: () => ({
        url: network.regularSchedule.getList,
        method: 'GET',
      }),
      providesTags: ['Regular'],
      transformResponse: (rawResult: ResponseData<RegularScheduleItemType[]>) => {
        return rawResult.data
      },
    }),
    getRegularScheduleItem: build.query<RegularScheduleItemType | undefined, unknown>({
      query: (id: number | string) => ({
        url: network.regularSchedule.getItem + id,
        method: 'GET',
      }),
      providesTags: ['Regular'],
      transformResponse: (rawResult: ResponseData<RegularScheduleItemType>) => {
        return rawResult.data
      },
    }),
    updateRegularScheduleItem: build.mutation({
      query: (params: UpdateRegularScheduleItemDto) => ({
        url: network.regularSchedule.updateItem + params.id,
        method: 'PUT',
        data: params,
      }),
      invalidatesTags: ['Regular'],
    }),
    deleteRegularScheduleItem: build.mutation({
      query: (id: string | number) => ({
        url: network.regularSchedule.deleteItem + id,
        method: 'DELETE',
      }),
      invalidatesTags: ['Regular'],
    }),
    getListRegularConfirm: build.query<RegularScheduleItemType[] | undefined, unknown>({
      query: (params: any) => ({
        url: network.regularSchedule.getListConfirm,
        method: 'GET',
        params: params,
      }),
      providesTags: ['Regular'],
      transformResponse: (rawResult: ResponseData<RegularScheduleItemType[]>) => {
        return rawResult.data
      },
    }),
    updateRegularConfirmItem: build.mutation({
      query: (params: UpdateRegularConfirmItemDto) => ({
        url: network.regularSchedule.updateConfirmItem,
        method: 'POST',
        data: params,
      }),
      invalidatesTags: ['Regular'],
    }),
  }),
})

export const {
  useGetListRegularScheduleQuery,
  useGetRegularScheduleItemQuery,
  useUpdateRegularScheduleItemMutation,
  useDeleteRegularScheduleItemMutation,
  useGetListRegularConfirmQuery,
  useUpdateRegularConfirmItemMutation,
} = regularScheduleApi

export default regularScheduleApi
