import React, { useEffect, useState } from 'react'
import { API_ROOT } from '../../constant/network'
import XMLViewer from 'react-xml-viewer'

const Sitemap: React.FC = () => {
  const [sitemap, setSitemap] = useState('')

  useEffect(() => {
    const fetchSitemap = async () => {
      try {
        const response = await fetch(API_ROOT + '/sitemap.xml')

        if (!response.ok) {
          throw new Error('Network response was not ok')
        }

        const xmlText = await response.text()
        setSitemap(xmlText)
      } catch (error) {
        console.error('Error fetching sitemap:', error)
      }
    }

    fetchSitemap()
  }, [])

  return <XMLViewer xml={sitemap} />
}

export default Sitemap
