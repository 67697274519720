import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { VoucherTab } from '../../../types/voucherType'

export const slice = createSlice({
  name: 'voucherPage',
  initialState: {
    tabList: {} as VoucherTab,
    activeTabId: 0,
  },
  reducers: {
    changeTabList: (state, action) => {
      state.tabList = action.payload
    },
    changeActiveTabId: (state, action) => {
      state.activeTabId = action.payload
    },
  },
})

export const selectTabList = (state: RootState) => state[slice.name].tabList
export const selectActiveTabId = (state: RootState) => state[slice.name].activeTabId

export const { changeTabList, changeActiveTabId } = slice.actions

export const VoucherPageReducer = slice.reducer
