import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../../app/store'
import { PromotionType } from '../../../../types/voucherType'

export const slice = createSlice({
  name: 'voucherPageCart',
  initialState: {
    voucherData: [] as Array<PromotionType>,
    detailVoucher: {} as PromotionType,
    idVoucherSelected: {} as any,
  },
  reducers: {
    changeVoucherData: (state, action) => {
      state.voucherData = action.payload
    },
    changeDetailVoucher: (state, action) => {
      state.detailVoucher = action.payload
    },
    changeSelectedVoucher: (state, action) => {
      state.idVoucherSelected = { ...state.idVoucherSelected, [action.payload.group_id]: action.payload.voucher_id }
    },
    changeRemoveVoucher: (state, action) => {
      const { [action.payload.group_id]: _, ...newVoucher } = state.idVoucherSelected
      state.idVoucherSelected = newVoucher
    },
  },
})

export const selectVoucherData = (state: RootState) => state[slice.name].voucherData
export const detailVoucher = (state: RootState) => state[slice.name].detailVoucher
export const idVoucherSelected = (state: RootState) => state[slice.name].idVoucherSelected

export const { changeVoucherData, changeDetailVoucher, changeSelectedVoucher, changeRemoveVoucher } = slice.actions

export const VoucherPageCartReducer = slice.reducer
