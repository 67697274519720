import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { OrderType } from '../../../types/orderType'
import { PromotionType } from '../../../types/voucherType'

const slice = createSlice({
  name: 'orderScreen',
  initialState: {
    activeTabId: 0,
    orderList: [] as OrderType[],
    filterOrder: [],
    selectedVoucher: {} as PromotionType,
  },
  reducers: {
    changeActiveTabId: (state, action) => {
      state.activeTabId = action.payload
    },
    changeOrderList: (state, action) => {
      state.orderList = action.payload
    },
    changeFilterOrder: (state, action) => {
      state.filterOrder = action.payload
    },
    changeSelectedVoucher: (state, action) => {
      state.selectedVoucher = action.payload
    },
  },
})

export const selectActiveTabId = (state: RootState) => state[slice.name].activeTabId
export const selectOrderList = (state: RootState) => state[slice.name].orderList
export const selectFilterOrder = (state: RootState) => state[slice.name].filterOrder
export const selectSelectedVoucher = (state: RootState) => state[slice.name].selectedVoucher

export const { changeActiveTabId, changeOrderList, changeFilterOrder, changeSelectedVoucher } = slice.actions

export const OrderScreenReducer = slice.reducer
