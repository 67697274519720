import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

const initialState = {
  searchText: '',
  searchData: undefined,
}

export const slice = createSlice({
  name: 'searchResult',
  initialState: initialState,

  reducers: {
    changeSearchText: (state, action) => {
      state.searchText = action.payload
    },
  },
})

export const selectSearchText = (state: RootState) => state[slice.name].searchText

export const { changeSearchText } = slice.actions

export const SearchResultReducer = slice.reducer
