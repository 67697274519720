import { Token } from '../types'

export const KEYS = {
  ACCESS_TOKEN: 'access_token',
  NAVBAR_STATUS: 'navbar_status',
  USER: 'user',
  TEM_TOKEN: 'tem_token',
}

export const getNavbarStatus = () => {
  if (localStorage.getItem(KEYS.NAVBAR_STATUS) === '1') {
    return true
  } else {
    return false
  }
}

export const setNavbarStatus = (isNavOpen: boolean) => {
  if (isNavOpen) {
    localStorage.setItem(KEYS.NAVBAR_STATUS, '1')
  } else {
    localStorage.setItem(KEYS.NAVBAR_STATUS, '0')
  }
}

export const setTokens = ({ accessToken }: Token) => {
  localStorage.setItem(KEYS.ACCESS_TOKEN, accessToken)
}

export const setTemToken = (token: string | undefined) => {
  if (token) {
    localStorage.setItem(KEYS.TEM_TOKEN, token)
  }
}

export const getTemToken = () => {
  return localStorage.getItem(KEYS.TEM_TOKEN)
}

export const setUser = (userData: any) => {
  localStorage.setItem(KEYS.USER, JSON.stringify(userData))
}

export const getUser = () => {
  const user = localStorage.getItem(KEYS.USER)
  return user ? JSON.parse(user) : undefined
}

type GetToken = Partial<Token>

export const getTokens = (): GetToken => {
  const accessToken = localStorage.getItem(KEYS.ACCESS_TOKEN) || undefined
  return { accessToken }
}
