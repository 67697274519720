type Props = {
  to: string
  title: string
  children?: React.ReactNode | string
}

export const LinkText: React.FC<Props> = (props) => {
  return (
    <a href={props.to || '#'} title={props.title || ''}>
      {props.children}
    </a>
  )
}
