import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../hooks'
import { API_ROOT, network } from '../../../constant/network'
import { ResponseData } from '../../../types'
import { PopupType } from '../../../types/popupType'

export const popupApi = createApi({
  reducerPath: 'popupAPi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    getPopupByLang: build.mutation<PopupType | undefined, unknown>({
      query: (data?) => ({
        url: network.popup.getByLang,
        method: 'get',
        data: data,
      }),
      transformResponse: (rawResult: ResponseData<PopupType>) => {
        return rawResult.data
      },
    }),
  }),
})

export const { useGetPopupByLangMutation } = popupApi

export default popupApi
