import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { RegularScheduleItemType } from '../../../types/regularScheduleType'

export const slice = createSlice({
  name: 'editRegularItemPage',
  initialState: {
    data: undefined as RegularScheduleItemType | undefined,
    quantity: 1,
    regularType: 1,
  },
  reducers: {
    changeData: (state, action) => {
      state.data = action.payload
    },
    changeQuantity: (state, action) => {
      state.quantity = action.payload
    },
    changeRegularType: (state, action) => {
      state.regularType = action.payload
    },
  },
})

export const selectData = (state: RootState) => state[slice.name].data
export const selectQuantity = (state: RootState) => state[slice.name].quantity
export const selectRegularType = (state: RootState) => state[slice.name].regularType

export const { changeData, changeQuantity, changeRegularType } = slice.actions

export const EditRegularItemReducer = slice.reducer
