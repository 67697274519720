import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'
import postApi from './services/post'
import commonApi from './services/common'
import brandApi from './services/brand'
import authApi from './services/auth'
import userAddressApi from './services/user/userAddress'
import userWishListApi from './services/user/userProductWishList'
import productDetailApi from './services/productDetail'
import cartApi from './services/cart'
import orderApi from './services/order'
import homeApi from './services/home'
import userFavoriteApi from './services/user/userProductFavorite'
import userReviewApi from './services/userReview'
import prescriptionApi from './services/prescription'
import orderReviewApi from './services/orderReview'
import searchDugApi from './services/searchDrug'
import voucherApi from './services/voucher'
import categoryApi from './services/category'
import commentApi from './services/comment'
import userNotifyApi from './services/user/userNotify'
import pointApi from './services/point'
import regularScheduleApi from './services/regularSchedule'
import homeSearchApi from './services/homeSearch'
import bankApi from './services/bank'
import userBuyWithDealListApi from './services/user/buyWithDeal'
import popupApi from './services/popup'
import customerApi from './services/customer'

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(
      commonApi.middleware,
      postApi.middleware,
      brandApi.middleware,
      authApi.middleware,
      userAddressApi.middleware,
      userWishListApi.middleware,
      productDetailApi.middleware,
      cartApi.middleware,
      orderApi.middleware,
      homeApi.middleware,
      userFavoriteApi.middleware,
      userReviewApi.middleware,
      prescriptionApi.middleware,
      orderReviewApi.middleware,
      searchDugApi.middleware,
      voucherApi.middleware,
      categoryApi.middleware,
      commentApi.middleware,
      userNotifyApi.middleware,
      pointApi.middleware,
      regularScheduleApi.middleware,
      homeSearchApi.middleware,
      bankApi.middleware,
      userBuyWithDealListApi.middleware,
      popupApi.middleware,
      customerApi.middleware,
    ),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// export type AppState = ReturnType<typeof rootReducer>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
