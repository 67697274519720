import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { giftListSortData } from '../../../constant'
import { Category } from '../../../types'

export const slice = createSlice({
  name: 'giftListPage',
  initialState: {
    categoryList: [] as Array<Category>,
    maxPoint: 100,
    giftList: [] as any,
    customerPoint: 0,
    filter: {
      order_by: giftListSortData[0].order_by,
      order_sort: giftListSortData[0].order_sort,
      sortValue: 0,
      points: { min: 0, max: 0 },
      freeShip: false,
      checkAllPoint: false,
      checkedCategory: {} as any,
      checkAllCategory: false,
    },
    paginate: {
      currentPage: 1,
      lastPage: 1,
    },
  },
  reducers: {
    changeFilter: (state, action) => {
      state.filter = action.payload
    },
    changeGiftList: (state, action) => {
      state.giftList = action.payload
    },
  },
})

export const selectFilter = (state: RootState) => state[slice.name].filter
export const selectCategoryList = (state: RootState) => state[slice.name].categoryList
export const selectMaxPoint = (state: RootState) => state[slice.name].maxPoint
export const selectCustomerPoint = (state: RootState) => state[slice.name].customerPoint
export const selectPaginate = (state: RootState) => state[slice.name].paginate
export const selectGiftList = (state: RootState) => state[slice.name].giftList

export const { changeFilter, changeGiftList } = slice.actions

export const GiftListReducer = slice.reducer
