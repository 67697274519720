import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { BankType } from '../../../types'

export const slice = createSlice({
  name: 'returnOrderPage',
  initialState: {
    selectedBank: undefined as BankType | undefined,
  },
  reducers: {
    changSelectedBank: (state, action) => {
      state.selectedBank = action.payload
    },
  },
})

export const selectSelectedBank = (state: RootState) => state[slice.name].selectedBank

export const { changSelectedBank } = slice.actions

export const ReturnOrderReducer = slice.reducer
