import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { ProductDetailType } from '../../../types'

import {
  ProductDetailType as DetailProductType,
  FrequencyOptionType,
  ProductComboType,
  ProductDocType,
  ProductReviewScoreType,
  PromotionType,
  ReviewDataType,
  SimilarProductType,
  VariantType,
} from '../../../types/productDetailType'

const initialState = {
  productData: {} as DetailProductType,
  data: {} as ProductDetailType,
  productImg: [] as string[],
  typeList: [] as VariantType[],
  selectedType: 0,
  voucherList: [] as PromotionType[],
  selectedVoucher: 0,
  comboList: [] as ProductComboType[],
  similarProductList: [] as SimilarProductType[],
  productMeta: {} as ProductDocType,
  reviewList: [] as ReviewDataType[],
  rateDetail: {} as ProductReviewScoreType,
  periodOption: undefined as FrequencyOptionType[] | undefined,
  selectedPeriod: undefined as FrequencyOptionType | undefined,
  quantity: 1,
  currReviewPage: 1,
  reviewPageSize: 10,
  totalReview: 0,
  productWishList: [] as Array<number>,
  productPrice: 0,
  discountPercent: 0,
  discountPrice: 0,
  originalPrice: 0,
  productCode: '',
  selectFirstTime: true,

  isSelectPackage: false,
  selectedPackage: undefined as ProductComboType | undefined,
  buyMethod: 0,
}

export const slice = createSlice({
  name: 'productDetail',
  initialState: initialState,

  reducers: {
    changeData: (state, action) => {
      state.data = action.payload
    },
    changeProductData: (state, action) => {
      state.productData = action.payload
    },
    changeProductImg: (state, action) => {
      state.productImg = action.payload
    },
    changeTypeList: (state, action) => {
      state.typeList = action.payload
    },
    changeVoucherList: (state, action) => {
      state.voucherList = action.payload
    },
    changeComboList: (state, action) => {
      state.comboList = action.payload
    },
    changeSimilarProductList: (state, action) => {
      state.similarProductList = action.payload
    },
    changeProductMeta: (state, action) => {
      state.productMeta = action.payload
    },
    changeReviewList: (state, action) => {
      state.reviewList = action.payload
    },
    changeSelectedType: (state, action) => {
      state.selectedType = action.payload
    },
    changeSelectedVoucher: (state, action) => {
      state.selectedVoucher = action.payload
    },
    changeRateDetail: (state, action) => {
      state.rateDetail = action.payload
    },
    changeSelectedPeriod: (state, action) => {
      state.selectedPeriod = action.payload
    },
    changeQuantity: (state, action) => {
      state.quantity = action.payload
    },
    changeCurrReviewPage: (state, action) => {
      state.currReviewPage = action.payload
    },
    changeTotalReview: (state, action) => {
      state.totalReview = action.payload
    },
    changeProductWishList: (state, action) => {
      state.productWishList = action.payload
    },
    changeProductPrice: (state, action) => {
      state.productPrice = action.payload
    },
    changeDiscountPercent: (state, action) => {
      state.discountPercent = action.payload
    },
    changeDiscountPrice: (state, action) => {
      state.discountPrice = action.payload
    },
    changePeriodOption: (state, action) => {
      state.periodOption = action.payload
    },
    changeOriginalPrice: (state, action) => {
      state.originalPrice = action.payload
    },
    changeIsSelectPackage: (state, action) => {
      state.isSelectPackage = action.payload
    },
    changeProductCode: (state, action) => {
      state.productCode = action.payload
    },
    changeSelectedPackage: (state, action) => {
      state.selectedPackage = action.payload
    },
    changeBuyMethod: (state, action) => {
      state.buyMethod = action.payload
    },
    changeSelectFirstTime: (state, action) => {
      state.selectFirstTime = action.payload
    },
    resetProuctDetailStore: () => initialState,
  },
})

export const selectData = (state: RootState) => state[slice.name].data
export const selectProductData = (state: RootState) => state[slice.name].productData
export const selectProductImg = (state: RootState) => state[slice.name].productImg
export const selectTypeList = (state: RootState) => state[slice.name].typeList
export const selectVoucherList = (state: RootState) => state[slice.name].voucherList
export const selectComboList = (state: RootState) => state[slice.name].comboList
export const selectSimilarProductList = (state: RootState) => state[slice.name].similarProductList
export const selectProductMeta = (state: RootState) => state[slice.name].productMeta
export const selectReviewList = (state: RootState) => state[slice.name].reviewList
export const selectSelectedType = (state: RootState) => state[slice.name].selectedType
export const selectSelectedVoucher = (state: RootState) => state[slice.name].selectedVoucher
export const selectRateDetail = (state: RootState) => state[slice.name].rateDetail
export const selectPeriodOption = (state: RootState) => state[slice.name].periodOption
export const selectSelectedPeriod = (state: RootState) => state[slice.name].selectedPeriod
export const selectQuantity = (state: RootState) => state[slice.name].quantity
export const selectReviewPageSize = (state: RootState) => state[slice.name].reviewPageSize
export const selectCurrReviewPage = (state: RootState) => state[slice.name].currReviewPage
export const selecttotalReview = (state: RootState) => state[slice.name].totalReview
export const selectProductWishList = (state: RootState) => state[slice.name].productWishList
export const selectProductPrice = (state: RootState) => state[slice.name].productPrice
export const selectDiscountPercent = (state: RootState) => state[slice.name].discountPercent
export const selectDiscountPrice = (state: RootState) => state[slice.name].discountPrice
export const selectOriginalPrice = (state: RootState) => state[slice.name].originalPrice
export const selectIsSelectPackage = (state: RootState) => state[slice.name].isSelectPackage
export const selectSelectedPackage = (state: RootState) => state[slice.name].selectedPackage
export const selectBuyMethod = (state: RootState) => state[slice.name].buyMethod
export const selectProductCode = (state: RootState) => state[slice.name].productCode
export const selectFirstTimeData = (state: RootState) => state[slice.name].selectFirstTime

export const {
  changeData,
  changeProductData,
  changeProductImg,
  changeTypeList,
  changeVoucherList,
  changeComboList,
  changeSimilarProductList,
  changeProductMeta,
  changeReviewList,
  changeSelectedType,
  changeSelectedVoucher,
  changeRateDetail,
  changeSelectedPeriod,
  changeQuantity,
  changeCurrReviewPage,
  changeTotalReview,
  changeProductWishList,
  changeProductPrice,
  changeDiscountPercent,
  changeDiscountPrice,
  changePeriodOption,
  changeOriginalPrice,
  changeIsSelectPackage,
  changeProductCode,
  changeSelectedPackage,
  changeBuyMethod,
  resetProuctDetailStore,
  changeSelectFirstTime,
} = slice.actions

export const ProductDetailReducer = slice.reducer
