import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { Post } from '../../../types/postType'

export const slice = createSlice({
  name: 'postSlice',
  initialState: {
    postdata: null as Post | null,
  },
  reducers: {
    changePostData: (state, action) => {
      state.postdata = action.payload
    },
  },
})

export const selectPostData = (state: RootState) => state[slice.name].postdata

export const { changePostData } = slice.actions

export const PostDetailReducer = slice.reducer
