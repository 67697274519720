import { lazy } from 'react'
import { ROUTER, RouterProps } from './config'

const HomeScreen = lazy(() => import('../pages/home'))
const ProductDetailScreen = lazy(() => import('../pages/productDetailScreen'))
const SearchHome = lazy(() => import('../pages/homeSearch'))
const UserScreen = lazy(() => import('../pages/user'))
const UserAccount = lazy(() => import('../pages/userAccount'))
const UserProduct = lazy(() => import('../pages/userProduct'))
const UserReview = lazy(() => import('../pages/userReview'))
const UserPaymentMethod = lazy(() => import('../pages/userPaymentMethod'))
const UserHistoryPayment = lazy(() => import('../pages/userHistoryPayment'))
const OrderScreen = lazy(() => import('../pages/order'))
const OrderDetailScreen = lazy(() => import('../pages/orderDetail'))
const VoucherScreen = lazy(() => import('../pages/voucher'))
const PointScreen = lazy(() => import('../pages/point'))
const LoginScreen = lazy(() => import('../pages/login'))
const RegisterScreen = lazy(() => import('../pages/register'))
const BrandingScreen = lazy(() => import('../pages/branding'))
const BrandDetailScreen = lazy(() => import('../pages/brandDetail'))
const SearchResult = lazy(() => import('../pages/searchResult'))
const CategoryScreen = lazy(() => import('../pages/category'))
const SearchDrugScreen = lazy(() => import('../pages/searchDrug'))
const DrugKnowledgeScreen = lazy(() => import('../pages/drugKnowledge'))
const DrugKnowledgeDetail = lazy(() => import('../pages/drugKnowledgeDetail'))
const PolicyScreen = lazy(() => import('../pages/policy'))
const PolicyDetailScreen = lazy(() => import('../pages/policyPages'))
const DeliveryPolicyScreen = lazy(() => import('../pages/policyPages/deliveryPolicy'))
const ExchangePolicyScreen = lazy(() => import('../pages/policyPages/exchangePolicy'))
const TermsOfServiceScreen = lazy(() => import('../pages/policyPages/serviceTerm'))
const PrivacyPolicyScreen = lazy(() => import('../pages/policyPages/privatePolicy'))
const AboutOmiPharmaScreen = lazy(() => import('../pages/aboutOmi'))
const CartScreen = lazy(() => import('../pages/cart'))
const PrescriptionsScreen = lazy(() => import('../pages/prescription'))
const PrescriptionDetailScreen = lazy(() => import('../pages/prescriptionDetail'))
const NewsScreen = lazy(() => import('../pages/news'))
const PostDetailScreen = lazy(() => import('../pages/postDetail'))
const PostPrescriptionScreen = lazy(() => import('../pages/postPrescription'))
const PostPrescriptionImg = lazy(() => import('../pages/postPrescription/postImg'))
const AddressListScreen = lazy(() => import('../pages/addressList'))
const UserAddressForm = lazy(() => import('../pages/addressForm'))
const UserAddressDetail = lazy(() => import('../pages/addressDetail'))
const CreateOrderScreen = lazy(() => import('../pages/createOrder'))
const OrderResultScreen = lazy(() => import('../pages/orderResult'))
const CancelOrderScreen = lazy(() => import('../pages/cancelOrder'))
const ReturnOrder = lazy(() => import('../pages/returnOrder'))
const RegularScheduleScreen = lazy(() => import('../pages/regularSchedule'))
const RegularItemScreen = lazy(() => import('../pages/regularItem'))
const EditRegularItemScreen = lazy(() => import('../pages/editRegularItem'))
const PromotionScreen = lazy(() => import('../pages/promotion'))
const UserReviewDetailScreen = lazy(() => import('../pages/userReviewDetail'))
const UserNotify = lazy(() => import('../pages/userNotify'))
const NotifyPage = lazy(() => import('../pages/userNotify/NotifyPage'))
const EnterPrescription = lazy(() => import('../pages/postPrescription/enterPrescription'))
const NoPrescription = lazy(() => import('../pages/postPrescription/NoPrescription'))
const HealthyScreen = lazy(() => import('../pages/health'))
const HealthCategoryScreen = lazy(() => import('../pages/healthCategory'))
const HealthPostDetailScreen = lazy(() => import('../pages/healthPostDetail'))
const TagDetailScreen = lazy(() => import('../pages/tagDetail'))
const BuyWithPrescriptionScreen = lazy(() => import('../pages/buyWithPrescription'))
const CampaignScreen = lazy(() => import('../pages/campaign'))
const GiftScreen = lazy(() => import('../pages/gift'))
const GiftDetailScreen = lazy(() => import('../pages/giftDetail'))
const GiftListScreen = lazy(() => import('../pages/giftList'))
const ForgotPassWord = lazy(() => import('../pages/forgotPass'))
const CartVoucher = lazy(() => import('../pages/cart/voucher/index'))
const ListStoreScreen = lazy(() => import('../pages/listStorePharma'))
const CategoryProduct = lazy(() => import('../pages/category/categoryProduct'))
const newsEvnet = lazy(() => import('../pages/newsAndEvent'))
const PreviewProduct = lazy(() => import('../pages/preview/productDetailScreen'))
const PreviewNews = lazy(() => import('../pages/preview/postDetail'))
const PreviewHealth = lazy(() => import('../pages/preview/healthPostDetail'))
const RegularConfirmScreen = lazy(() => import('../pages/regularConfirm'))
const OrderGuideScreen = lazy(() => import('../pages/policyPages/orderGuide'))

export const ROUTERS: RouterProps[] = [
  {
    path: ROUTER.HOME_PAGE,
    element: HomeScreen,
  },
  {
    path: ROUTER.PRODUCT_DETAIL_SCREEN,
    element: ProductDetailScreen,
  },
  {
    path: ROUTER.SEARCH_HOME,
    element: SearchHome,
  },
  {
    path: ROUTER.ORDER,
    element: OrderScreen,
  },
  {
    path: ROUTER.ORDER_DETAIL,
    element: OrderDetailScreen,
  },
  {
    path: ROUTER.LOGIN,
    element: LoginScreen,
  },
  {
    path: ROUTER.REGISTER,
    element: RegisterScreen,
  },
  {
    path: ROUTER.BRANDING,
    element: BrandingScreen,
  },
  {
    path: ROUTER.BRAND_DETAIL,
    element: BrandDetailScreen,
  },
  {
    path: ROUTER.SEARCH_RESULT,
    element: SearchResult,
  },
  {
    path: ROUTER.CATEGORY,
    element: CategoryScreen,
  },
  {
    path: ROUTER.CATEGORY_PRODUCT,
    element: CategoryProduct,
  },
  {
    path: ROUTER.DRUG_SEARCH,
    element: SearchDrugScreen,
  },
  {
    path: ROUTER.DRUG_KNOWLEDGE,
    element: DrugKnowledgeScreen,
  },
  {
    path: ROUTER.DRUG_KNOWLEDGE_DETAIL,
    element: DrugKnowledgeDetail,
  },
  {
    path: ROUTER.POLICY,
    element: PolicyScreen,
  },
  {
    path: ROUTER.POLICY_DETAIL,
    element: PolicyDetailScreen,
  },
  {
    path: ROUTER.DELIVERY_POLICY,
    element: DeliveryPolicyScreen,
  },
  {
    path: ROUTER.EXCHANGE_POLICY,
    element: ExchangePolicyScreen,
  },
  {
    path: ROUTER.SERVICE_TERM,
    element: TermsOfServiceScreen,
  },
  {
    path: ROUTER.PRIVATE_POLICY,
    element: PrivacyPolicyScreen,
  },
  {
    path: ROUTER.ORDERING_GUILD,
    element: OrderGuideScreen,
  },
  {
    path: ROUTER.ABOUT_OMI_PHARMA,
    element: AboutOmiPharmaScreen,
  },
  {
    path: ROUTER.CART,
    element: CartScreen,
  },
  {
    path: ROUTER.CREATE_ORDER,
    element: CreateOrderScreen,
  },
  {
    path: ROUTER.ORDER_RESULT,
    element: OrderResultScreen,
  },
  {
    path: ROUTER.ORDER_CANCEL_REQUEST_DETAIL,
    element: CancelOrderScreen,
  },
  {
    path: ROUTER.NEWS,
    element: NewsScreen,
  },
  {
    path: ROUTER.NEWS_DETAIL,
    element: PostDetailScreen,
  },
  {
    path: ROUTER.NEWS_REVIEW_SCREEN,
    element: NewsScreen,
    props: {
      type: 4,
    },
  },
  {
    path: ROUTER.NEWS_REVIEW_DETAIL_SCREEN,
    element: PostDetailScreen,
    props: {
      type: 4,
    },
  },
  {
    path: ROUTER.PROMOTION,
    element: PromotionScreen,
  },
  {
    path: ROUTER.HEALTH,
    element: HealthyScreen,
  },
  {
    path: ROUTER.HEALTH_CATEGORY,
    element: HealthCategoryScreen,
  },
  {
    path: ROUTER.HEALTH_POST_DETAIL,
    element: HealthPostDetailScreen,
    props: {
      type: 3, //Sống khẻ
    },
  },
  {
    path: ROUTER.TAG_PAGE_DETAIL,
    element: TagDetailScreen,
  },
  {
    path: ROUTER.BUY_WITH_PRESCRIPTION,
    element: BuyWithPrescriptionScreen,
  },
  {
    path: ROUTER.CAMPAIGN_DETAIL,
    element: CampaignScreen,
  },
  {
    path: ROUTER.GIFT,
    element: GiftScreen,
  },
  {
    path: ROUTER.GIFT_LIST,
    element: GiftListScreen,
  },
  {
    path: ROUTER.GIFT_DETAIL_ITEM,
    element: GiftDetailScreen,
  },
  {
    path: ROUTER.FORGOT,
    element: ForgotPassWord,
  },
  {
    path: ROUTER.CART_VOUCHER,
    element: CartVoucher,
  },
  {
    path: ROUTER.STORE_OMI_PHARMA,
    element: ListStoreScreen,
  },
  {
    path: ROUTER.REGULAR_CONFIRM_SCREEN,
    element: RegularConfirmScreen,
  },
  {
    path: ROUTER.PRESCRIPTION,
    element: PrescriptionsScreen,
  },
  {
    path: ROUTER.PRESCRIPTION_DETAIL,
    element: PrescriptionDetailScreen,
  },
  {
    path: ROUTER.POST_PRESCRIPTION,
    element: PostPrescriptionScreen,
  },
  {
    path: ROUTER.POST_PRESCRIPTION_IMG,
    element: PostPrescriptionImg,
  },
  {
    path: ROUTER.ENTER_PRESCRIPTION,
    element: EnterPrescription,
  },
  {
    path: ROUTER.NO_PRESCRIPTION,
    element: NoPrescription,
  },
]

export const PRIVATE_ROUTE: RouterProps[] = [
  {
    path: ROUTER.USER,
    element: UserScreen,
  },
  {
    path: ROUTER.USER_ACCOUNT,
    element: UserAccount,
  },
  {
    path: ROUTER.USER_PRODUCT,
    element: UserProduct,
  },
  {
    path: ROUTER.USER_REVIEW,
    element: UserReview,
  },
  {
    path: ROUTER.USER_PAYMENT_METHOD,
    element: UserPaymentMethod,
  },
  {
    path: ROUTER.USER_PAYMENT_HISTORY,
    element: UserHistoryPayment,
  },
  {
    path: ROUTER.USER_VOUCHER,
    element: VoucherScreen,
  },
  {
    path: ROUTER.USER_POINT,
    element: PointScreen,
  },
  {
    path: ROUTER.ADDRESS_LIST,
    element: AddressListScreen,
  },
  {
    path: ROUTER.EDIT_ADDRESS,
    element: UserAddressForm,
  },
  {
    path: ROUTER.ADD_ADDRESS,
    element: UserAddressForm,
  },
  {
    path: ROUTER.ADDRESS_DETAIL,
    element: UserAddressDetail,
  },
  {
    path: ROUTER.USER_REGULAR_SCHEDULE,
    element: RegularScheduleScreen,
  },
  {
    path: ROUTER.USER_REGULAR_DETAIL,
    element: RegularItemScreen,
  },
  {
    path: ROUTER.USER_REGULAR_EDIT_ITEM,
    element: EditRegularItemScreen,
  },
  {
    path: ROUTER.USER_REVIEW_DETAIL,
    element: UserReviewDetailScreen,
  },
  {
    path: ROUTER.USER_NOTIFY,
    element: UserNotify,
  },
  {
    path: ROUTER.USER_NOTIFY_TAB,
    element: NotifyPage,
  },
  {
    path: ROUTER.NEWS_EVENT,
    element: newsEvnet,
  },
  {
    path: ROUTER.NEWS_EVENT_POST_DETAIL,
    element: HealthPostDetailScreen,
    props: {
      type: 2, //Tin tức sự kiện
    },
  },
  {
    path: ROUTER.ORDER_RETURN_REQUEST_DETAIL,
    element: ReturnOrder,
  },
  {
    path: ROUTER.PREVIEW_PRODUCT,
    element: PreviewProduct,
  },
  {
    path: ROUTER.PREVIEW_NEWS,
    element: PreviewNews,
    props: {
      type: 2,
    },
  },
  {
    path: ROUTER.PREVIEW_NEWS_REVIEW,
    element: PreviewNews,
    props: {
      type: 4,
    },
  },
  {
    path: ROUTER.PREVIEW_HEALTH,
    element: PreviewHealth,
    props: {
      type: 3,
    },
  },
]
