import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { PersonalOptionType, UserProductCategoryType, UserSupportOptionType } from '../constants'

export const slice = createSlice({
  name: 'userScreen',
  initialState: {
    statusList: [],
    productCategory: [] as Array<UserProductCategoryType>,
    personalOption: [] as Array<PersonalOptionType>,
    supportOption: [] as Array<UserSupportOptionType>,
  },
  reducers: {
    changeStatusList: (state, action) => {
      state.statusList = action.payload
    },
    changeProductCategory: (state, action) => {
      state.productCategory = action.payload
    },
    changePersonalOption: (state, action) => {
      state.personalOption = action.payload
    },
    changeSupportOption: (state, action) => {
      state.supportOption = action.payload
    },
  },
})

export const selectStatusList = (state: RootState) => state[slice.name].statusList
export const selectProductCategory = (state: RootState) => state[slice.name].productCategory
export const selectPersonalOption = (state: RootState) => state[slice.name].personalOption
export const selectSupportOption = (state: RootState) => state[slice.name].supportOption

export const { changeStatusList, changeProductCategory, changePersonalOption, changeSupportOption } = slice.actions

export const UserScreenReducer = slice.reducer
