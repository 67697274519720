import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { Product } from '../../../types'

export const slice = createSlice({
  name: 'giftDetailPage',
  initialState: {
    paginate: {
      currentPage: 1,
      lastPage: 1,
    },
    productList: [] as Array<Product>,
  },
  reducers: {
    changePaginate: (state, action) => {
      state.paginate = action.payload
    },
    changeProductList: (state, action) => {
      state.productList = action.payload
    },
  },
})

export const selectPaginate = (state: RootState) => state[slice.name].paginate
export const selectProductList = (state: RootState) => state[slice.name].productList
export const { changePaginate, changeProductList } = slice.actions

export const GiftDetailPageReducer = slice.reducer
