import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../../hooks'
import { API_ROOT, network } from '../../../../constant/network'
import { ResponseData } from '../../../../types'
import { GetComboListDto, GetRelatedProductDto, UserProductComboItemType } from '../../../../types/dealListType'
import { BriefProduct } from '../../../../types/productDetailType'

const userBuyWithDealListApi = createApi({
  reducerPath: 'userBuyWithDealListApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    getComboList: build.query<ResponseData<UserProductComboItemType[]> | undefined, unknown>({
      query: (params: GetComboListDto) => ({
        url: network.userProductComboList.getList,
        method: 'get',
        params: params,
      }),
    }),
    getComboRelatedProduct: build.mutation<BriefProduct[] | undefined, unknown>({
      query: (params: GetRelatedProductDto) => ({
        url: network.userProductComboList.getRelatedProducts,
        method: 'post',
        data: params,
      }),
      transformResponse: (rawResult: ResponseData<BriefProduct[]>) => {
        return rawResult.data
      },
    }),
  }),
})

export const { useLazyGetComboListQuery, useGetComboRelatedProductMutation } = userBuyWithDealListApi

export default userBuyWithDealListApi
