import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../hooks'
import { API_ROOT, network } from '../../../constant/network'
import { ResponseData } from '../../../types'
import { OrderReviewDto, ReviewSuggest } from '../../../types/orderReiew'

export const orderReviewApi = createApi({
  reducerPath: 'orderReviewApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    orderReview: build.mutation<ResponseData<[]>, unknown>({
      query: (data: OrderReviewDto) => ({
        url: network.orderReview.reviewOrder,
        method: 'post',
        data: data,
      }),
    }),
    getProductReviewSuggest: build.query<ReviewSuggest[] | undefined, unknown>({
      query: () => ({
        url: network.orderReview.reviewSuggest,
        method: 'get',
      }),
      transformResponse: (raw: ResponseData<ReviewSuggest[]>) => raw.data,
    }),
  }),
})

export const { useOrderReviewMutation, useGetProductReviewSuggestQuery } = orderReviewApi

export default orderReviewApi
