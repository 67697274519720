import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { CityType, DistrictType, StoreType, WardType } from '../../../types'
import { OrderType, PaymentMethodItem, ShippingMethodType } from '../../../types/orderType'

interface error {
  message: string
  type: string
}
interface errorsReceive {
  email: error
  userName: error
  phoneNumber: error
}
export const slice = createSlice({
  name: 'createOrderPage',
  initialState: {
    isChoooseAddress: false,
    cityList: [] as CityType[],
    districtList: [] as DistrictType[],
    wardList: [] as WardType[],
    //receiveGoodsAtThePharmacy
    type: 1,
    date: new Date(),
    time: '',
    storeSelected: {} as StoreType,
    userName: '',
    phoneNumber: '',
    email: '',
    errorsReceive: {} as errorsReceive,
    order: undefined as OrderType | undefined | any,
    paymentMethod: [] as PaymentMethodItem[],
    confirmSuccess: false,
    shippingMethod: [] as ShippingMethodType[],

    contributor: undefined as string | undefined,
    channelId: undefined as string | undefined,
    campaignId: undefined as string | undefined,
    slug: undefined as string | undefined,
    url: undefined as string | undefined,
  },
  reducers: {
    changeIsChoooseAddress: (state, action) => {
      state.isChoooseAddress = action.payload
    },
    changeCityList: (state, action) => {
      state.cityList = action.payload
    },
    changeDistrictList: (state, action) => {
      state.districtList = action.payload
    },
    changeWardList: (state, action) => {
      state.wardList = action.payload
    },
    //receiveGoodsAtThePharmacy
    changeTypeReceive: (state, action) => {
      state.type = action.payload
    },
    changeTimeReceive: (state, action) => {
      state.time = action.payload
    },
    changeDateReceive: (state, action) => {
      state.date = action.payload
    },
    changeStoreReceive: (state, action) => {
      state.storeSelected = action.payload
    },
    changeErrorsReceive: (state, action) => {
      state.errorsReceive = action.payload
    },
    changeInfoUserReceive: (state, action) => {
      const { key, value } = action.payload as { key: 'email' | 'phoneNumber' | 'userName'; value: string }
      state[key] = value
    },
    changeUserReceive: (state, action) => {
      state.userName = action.payload.userName
      state.phoneNumber = action.payload.phoneNumber
      state.email = action.payload.email
    },
    changeStateData: (state, action) => {
      state.paymentMethod = action.payload.payment_method || []
      state.shippingMethod = action.payload.shipping_method || []
      state.order = action.payload.order
    },
    changeContributorId: (state, action) => {
      state.contributor = action.payload
    },
    changeChannelId: (state, action) => {
      state.channelId = action.payload
    },
    changeCampaignId: (state, action) => {
      state.campaignId = action.payload
    },
    changeSlug: (state, action) => {
      state.slug = action.payload
    },
    changeUrl: (state, action) => {
      state.url = action.payload
    },
  },
})

export const selectIsChoooseAddress = (state: RootState) => state[slice.name].isChoooseAddress
export const selectCityList = (state: RootState) => state[slice.name].cityList
export const selectWardList = (state: RootState) => state[slice.name].wardList
export const selectDistrictList = (state: RootState) => state[slice.name].districtList
export const selectReceiveGoodsAtThePharmacy = (state: RootState) => state[slice.name]
export const selectOrder = (state: RootState) => state[slice.name].order
export const selectConfirmSuccess = (state: RootState) => state[slice.name].confirmSuccess
export const selectPaymentMethod = (state: RootState) => state[slice.name].paymentMethod
export const selectShippingMethod = (state: RootState) => state[slice.name].shippingMethod

export const selectContributor = (state: RootState) => state[slice.name].contributor
export const selectChannel = (state: RootState) => state[slice.name].channelId
export const selectCampaign = (state: RootState) => state[slice.name].campaignId
export const selectSlug = (state: RootState) => state[slice.name].slug
export const selectUrl = (state: RootState) => state[slice.name].url

export const {
  changeIsChoooseAddress,
  changeCityList,
  changeDistrictList,
  changeWardList,
  changeTypeReceive,
  changeDateReceive,
  changeTimeReceive,
  changeStoreReceive,
  changeErrorsReceive,
  changeInfoUserReceive,
  changeUserReceive,
  changeStateData,

  changeContributorId,
  changeChannelId,
  changeCampaignId,
  changeSlug,
  changeUrl,
} = slice.actions

export const CreatOrderReducer = slice.reducer
