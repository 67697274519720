import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { Post } from '../../../types/postType'

export const slice = createSlice({
  name: 'newsPage',
  initialState: {
    currentPage: 1,
    lastPage: 1,
    postListData: [] as Post[],
  },
  reducers: {
    changePostListData: (state, action) => {
      state.postListData = action.payload
    },
    changeCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    changeLastPage: (state, action) => {
      state.lastPage = action.payload
    },
  },
})

export const selectCurrentPage = (state: RootState) => state[slice.name].currentPage
export const selectLastPage = (state: RootState) => state[slice.name].lastPage
export const selectPostListData = (state: RootState) => state[slice.name].postListData

export const { changePostListData, changeCurrentPage, changeLastPage } = slice.actions

export const NewsPageReducer = slice.reducer
