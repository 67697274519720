import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ROOT, network } from '../../../constant/network'
import { ResponseData } from '../../../types'
import {
  LoginData,
  LoginRequest,
  LoginSocialType,
  TemTokenData,
  UserData,
  VerifyCheckRequest,
  VerifyRequest,
  changePassType,
  forgotType,
  userType,
} from '../../../types/auth'
import { axiosBaseQuery } from '../../hooks'

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    fetchLogin: build.mutation<ResponseData<LoginData>, unknown>({
      query: (param: LoginRequest) => ({
        url: network.auth.login,
        method: 'post',
        data: param,
      }),
      transformResponse: (rawResult: ResponseData<LoginData>) => {
        return rawResult
      },
    }),
    getUser: build.query<ResponseData<userType>, unknown>({
      query: () => ({
        url: network.users.getOne,
        method: 'get',
      }),
      transformResponse: (rawResult: ResponseData<userType>) => {
        return rawResult
      },
    }),
    veryEmail: build.mutation<ResponseData<UserData>, unknown>({
      query: (param: VerifyRequest) => ({
        url: network.auth.verify,
        method: 'post',
        data: param,
      }),
      transformResponse: (rawResult: ResponseData<UserData>) => {
        return rawResult
      },
    }),
    veryCheck: build.mutation<ResponseData<UserData>, unknown>({
      query: (param: VerifyCheckRequest) => ({
        url: network.auth.verifyCheck,
        method: 'post',
        data: param,
      }),
      transformResponse: (rawResult: ResponseData<UserData>) => {
        return rawResult
      },
    }),
    register: build.mutation<ResponseData<UserData>, unknown>({
      query: (param: any) => ({
        url: network.auth.register,
        method: 'post',
        data: param,
      }),
      transformResponse: (rawResult: ResponseData<UserData>) => {
        return rawResult
      },
    }),
    forgot: build.mutation<ResponseData<UserData>, unknown>({
      query: (param: forgotType) => ({
        url: network.auth.forgotPass,
        method: 'post',
        data: param,
      }),
      transformResponse: (rawResult: ResponseData<UserData>) => {
        return rawResult
      },
    }),
    updateUser: build.mutation<ResponseData<UserData>, unknown>({
      query: (param: forgotType) => ({
        url: network.auth.updateUser,
        method: 'post',
        data: param,
      }),
      transformResponse: (rawResult: ResponseData<UserData>) => {
        return rawResult
      },
    }),
    changePass: build.mutation<ResponseData<UserData>, unknown>({
      query: (param: changePassType) => ({
        url: network.auth.changePass,
        method: 'post',
        data: param,
      }),
      transformResponse: (rawResult: ResponseData<UserData>) => {
        return rawResult
      },
    }),
    loginSocial: build.mutation<ResponseData<LoginData>, unknown>({
      query: (param: LoginSocialType) => ({
        url: network.auth.loginSocial,
        method: 'post',
        data: param,
      }),
      transformResponse: (rawResult: ResponseData<LoginData>) => {
        return rawResult
      },
    }),
    getTemToken: build.query<ResponseData<TemTokenData>, unknown>({
      query: () => ({
        url: network.auth.getTemToken,
        method: 'get',
      }),
      transformResponse: (rawResult: ResponseData<TemTokenData>) => {
        return rawResult
      },
    }),
  }),
})

export const {
  useFetchLoginMutation,
  useGetUserQuery,
  useVeryEmailMutation,
  useVeryCheckMutation,
  useRegisterMutation,
  useForgotMutation,
  useUpdateUserMutation,
  useChangePassMutation,
  useLoginSocialMutation,
  useGetTemTokenQuery,
} = authApi

export default authApi
