import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { CityType, DistrictType } from '../../../types'

export const slice = createSlice({
  name: 'buyWithPrescriptionPage',
  initialState: {
    fileUpload: null as any,
    imageUpload: '',
    cityList: [] as CityType[],
    districtList: [] as DistrictType[],
  },
  reducers: {
    changFileUpload: (state, action) => {
      state.fileUpload = action.payload
    },
    changeImageUpload: (state, action) => {
      state.imageUpload = action.payload
    },
    changeCityList: (state, action) => {
      state.cityList = action.payload
    },
    changeDistrictList: (state, action) => {
      state.districtList = action.payload
    },
  },
})

export const selectFileUpload = (state: RootState) => state[slice.name].fileUpload
export const selectImageUpload = (state: RootState) => state[slice.name].imageUpload
export const selectCityList = (state: RootState) => state[slice.name].cityList
export const selectDistrictList = (state: RootState) => state[slice.name].districtList

export const { changFileUpload, changeImageUpload, changeDistrictList, changeCityList } = slice.actions

export const BuyWithPrescriptionReducer = slice.reducer
