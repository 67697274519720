import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { CartType, FrequencyType } from '../../../types/cartType'

export const slice = createSlice({
  name: 'cartPage',
  initialState: {
    productList: [] as any[],
    voucherList: [] as any[],
    selectedVoucher: {} as any,

    cartData: undefined as CartType | undefined,
    frequency: undefined as FrequencyType | undefined,
  },
  reducers: {
    changeProductList: (state, action) => {
      state.productList = action.payload
    },
    changeVoucherList: (state, action) => {
      state.voucherList = action.payload
    },
    changeSelectedVoucher: (state, action) => {
      state.selectedVoucher = { ...state.selectedVoucher, [action.payload.group_id]: action.payload.voucher_id }
    },

    changeFrequency: (state, action) => {
      state.frequency = action.payload
    },
    changeCartData: (state, action) => {
      state.cartData = action.payload
    },
  },
})

export const selectProductList = (state: RootState) => state[slice.name].productList
export const selectVoucherList = (state: RootState) => state[slice.name].voucherList
export const selectSelectedVoucher = (state: RootState) => state[slice.name].selectedVoucher

export const { changeProductList, changeSelectedVoucher, changeVoucherList, changeFrequency, changeCartData } =
  slice.actions

export const CartReducer = slice.reducer
