import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { Product } from '../../../types'

export const slice = createSlice({
  name: 'campaignPage',
  initialState: {
    paginate: {
      currentPage: 1,
      lastPage: 1,
    },
    productList: [] as Array<Product>,
    campaign: {} as any,
    title: '',
  },
  reducers: {
    changePaginate: (state, action) => {
      state.paginate = action.payload
    },
    changeCampaign: (state, action) => {
      state.campaign = action.payload
    },
    changeProductList: (state, action) => {
      state.productList = action.payload
    },
  },
})

export const selectPaginate = (state: RootState) => state[slice.name].paginate
export const selectCampaign = (state: RootState) => state[slice.name].campaign
export const selectProductList = (state: RootState) => state[slice.name].productList
export const { changePaginate, changeCampaign, changeProductList } = slice.actions

export const CampaignPageReducer = slice.reducer
