import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { CityType, DistrictType, WardType } from '../../../types'

export const slice = createSlice({
  name: 'addressForm',
  initialState: {
    cityList: [] as CityType[],
    districtList: [] as DistrictType[],
    wardList: [] as WardType[],
  },
  reducers: {
    changeCityList: (state, action) => {
      state.cityList = action.payload
    },
    changeDistrictList: (state, action) => {
      state.districtList = action.payload
    },
    changeWardList: (state, action) => {
      state.wardList = action.payload
    },
  },
})

export const selectCityList = (state: RootState) => state[slice.name].cityList
export const selectWardList = (state: RootState) => state[slice.name].wardList
export const selectDistrictList = (state: RootState) => state[slice.name].districtList

export const { changeCityList, changeDistrictList, changeWardList } = slice.actions

export const AddressFormReducer = slice.reducer
