import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ROOT, network } from '../../../../constant/network'
import { ResponseData } from '../../../../types'
import { AddItemToFavoriteDto, AddItemToFavoriteResponse, FavoriteItem } from '../../../../types/favoriteListType'
import { axiosBaseQuery } from '../../../hooks'

const userFavoriteApi = createApi({
  reducerPath: 'userFavoriteApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  tagTypes: ['Favorite'],
  endpoints: (build) => ({
    getFavoriteList: build.mutation<FavoriteItem[] | undefined, unknown>({
      query: () => ({
        url: network.userProductFavoriteList.getList,
        method: 'post',
      }),
      transformResponse: (rawResponse: ResponseData<FavoriteItem[]>) => {
        return rawResponse.data
      },
    }),
    addFavoriteItem: build.mutation<ResponseData<AddItemToFavoriteResponse> | undefined, unknown>({
      query: (param: AddItemToFavoriteDto) => ({
        url: network.userProductFavoriteList.addItem,
        method: 'POST',
        data: param,
      }),
      invalidatesTags: ['Favorite'],
    }),
    deleteFavoriteItem: build.mutation<ResponseData<number> | undefined, unknown>({
      query: (param: AddItemToFavoriteDto) => ({
        url: network.userProductFavoriteList.deleteItem,
        method: 'delete',
        data: param,
      }),
      invalidatesTags: ['Favorite'],
    }),
  }),
})

export const { useAddFavoriteItemMutation, useDeleteFavoriteItemMutation, useGetFavoriteListMutation } = userFavoriteApi

export default userFavoriteApi
