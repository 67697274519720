import { axiosBaseQuery } from '../../hooks'
import { API_ROOT, network } from '../../../constant/network'
import {
  CityType,
  DistrictType,
  MedicineItemType,
  ResponseData,
  SearchMedicineDto,
  StoreType,
  WardType,
  paramSearchShop,
} from '../../../types'
import { createApi } from '@reduxjs/toolkit/query/react'
import { PAGE_SIZE } from '../../../pages/searchDrug/constants'

export const commonApi = createApi({
  reducerPath: 'commonApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    getStoreList: build.query<StoreType[] | undefined, unknown>({
      query: () => ({
        url: network.store.getList,
        method: 'get',
      }),
      transformResponse: (rawResult: ResponseData<StoreType[]>) => {
        const data = rawResult.data
        return data
      },
    }),
    searchStoreList: build.mutation<StoreType[] | undefined, unknown>({
      query: (prams: paramSearchShop) => ({
        url: network.store.searchList,
        method: 'post',
        data: {
          filter: {
            ...prams,
          },
          pageSize: PAGE_SIZE,
        },
      }),
      transformResponse: (rawResult: ResponseData<StoreType[]>) => {
        const data = rawResult.data
        return data
      },
    }),
    getCityList: build.query<CityType[] | undefined, unknown>({
      query: () => ({
        url: network.city.getList,
        method: 'get',
      }),
      transformResponse: (rawResult: ResponseData<CityType[]>) => {
        const data = rawResult.data
        return data
      },
    }),
    getCity: build.query<CityType | undefined, unknown>({
      query: (id: string | number) => ({
        url: network.city.getItem(id),
        method: 'get',
      }),
      transformResponse: (rawResult: ResponseData<CityType>) => {
        return rawResult.data
      },
    }),
    getDistrictList: build.query<DistrictType[] | undefined, unknown>({
      query: (id: string | number) => ({
        url: network.district.getList,
        method: 'post',
        data: {
          filter: {
            province_id: { equal: id },
          },
          pageSize: 999,
        },
      }),
      transformResponse: (rawResult: ResponseData<DistrictType[]>) => {
        return rawResult.data
      },
    }),
    getDistrict: build.query<DistrictType | undefined, unknown>({
      query: (id: string | number) => ({
        url: network.district.getItem(id),
        method: 'get',
      }),
      transformResponse: (rawResult: ResponseData<DistrictType>) => {
        return rawResult.data
      },
    }),
    getWardList: build.query<WardType[] | undefined, unknown>({
      query: (id: string | number) => ({
        url: network.ward.getList,
        method: 'post',
        data: {
          filter: {
            district_id: { equal: id },
          },
          pageSize: 999,
        },
      }),
      transformResponse: (rawResult: ResponseData<WardType[]>) => {
        return rawResult.data
      },
    }),
    getWard: build.query<WardType | undefined, unknown>({
      query: (id: string | number) => ({
        url: network.ward.getItem(id),
        method: 'get',
      }),
      transformResponse: (rawResult: ResponseData<WardType>) => {
        return rawResult.data
      },
    }),
    searchMedicineList: build.mutation<MedicineItemType[] | undefined, unknown>({
      query: (param: SearchMedicineDto) => ({
        url: network.medicine.searchMedicine,
        method: 'post',
        data: param,
      }),
      transformResponse: (rawResult: ResponseData<MedicineItemType[]>) => {
        return rawResult.data
      },
    }),
  }),
})

export const {
  useGetStoreListQuery,
  useGetCityListQuery,
  useGetCityQuery,
  useGetDistrictListQuery,
  useGetDistrictQuery,
  useGetWardListQuery,
  useGetWardQuery,
  useSearchMedicineListMutation,
  useSearchStoreListMutation,
} = commonApi

export default commonApi
