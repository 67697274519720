import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { Tag } from '../../../types'
import { Post } from '../../../types/postType'

export const slice = createSlice({
  name: 'tagDetailPage',
  initialState: {
    paginate: {
      currentPage: 1,
      lastPage: 1,
    },
    listPostsByTag: [] as Array<Post>,
    tag: { name: '' } as Tag,
    title: '',
  },
  reducers: {
    changePaginate: (state, action) => {
      state.paginate = action.payload
    },
    changeListPostsByTag: (state, action) => {
      state.listPostsByTag = action.payload
    },
    changeTag: (state, action) => {
      state.tag = action.payload
    },
  },
})

export const selectPaginate = (state: RootState) => state[slice.name].paginate
export const selectListPostsByTag = (state: RootState) => state[slice.name].listPostsByTag
export const selectTag = (state: RootState) => state[slice.name].tag

export const { changePaginate, changeListPostsByTag, changeTag } = slice.actions

export const TagDetailPageReducer = slice.reducer
