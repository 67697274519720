import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

export const slice = createSlice({
  name: 'homePage',
  initialState: {
    isLoading: false,
  },
  reducers: {
    changeIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
  },
})

export const selectHomePage = (state: RootState) => state[slice.name]
export const { changeIsLoading } = slice.actions

export const HomePageReducer = slice.reducer
