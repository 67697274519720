// userSlice.js
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { initialAuth, userType } from '../../../types/auth'
const initialState: initialAuth = {
  user: {} as userType,
  registerStep: {
    step: 1,
    email: '',
    verify_type: 'register',
  },
}
const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.user = action.payload
    },
    //registerStep
    next: (state, action: PayloadAction<number>) => {
      state.registerStep.step = action.payload
    },
    reset: (state) => {
      state.registerStep.step = 1
      state.registerStep.email = ''
      state.registerStep.verify_type = 'register'
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.registerStep.email = action.payload
    },
    setVerifyType: (state, action: PayloadAction<string>) => {
      state.registerStep.verify_type = action.payload
    },
  },
})

export const { setUserInfo, next, reset, setUserEmail, setVerifyType } = userSlice.actions
export default userSlice.reducer
