import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

export const slice = createSlice({
  name: 'cancelOrderPage',
  initialState: {
    selectedBank: {} as any,
    showErrorSelectBank: false,
  },
  reducers: {
    changSelectedBank: (state, action) => {
      state.selectedBank = action.payload
    },
    changeShowErrorSelectBank: (state, action) => {
      state.showErrorSelectBank = action.payload
    },
  },
})

export const selectSelectedBank = (state: RootState) => state[slice.name].selectedBank
export const selectShowErrorSelectBank = (state: RootState) => state[slice.name].showErrorSelectBank

export const { changSelectedBank, changeShowErrorSelectBank } = slice.actions

export const CancelOrderReducer = slice.reducer
