import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { CityType, DistrictType, WardType } from '../../../types'
import { MedicineItemType } from '../../../types/postPrescription'

export const slice = createSlice({
  name: 'postPrescriptionPage',
  initialState: {
    fileList: [] as File[],
    preList: [] as Array<string>,

    medicineList: [] as Array<MedicineItemType>,
    selectedMedicineList: [] as Array<MedicineItemType>,

    cityList: [] as CityType[],
    districtList: [] as DistrictType[],
    wardList: [] as WardType[],

    uploadStatus: false,
    hasPrescription: false,
  },
  reducers: {
    // post prescription IMG
    changeFileList: (state, action) => {
      state.fileList = action.payload
    },
    changePreList: (state, action) => {
      state.preList = action.payload
    },
    // enter medicine
    changeMedicineList: (state, action) => {
      state.medicineList = action.payload
    },
    changeSelectedMedicineList: (state, action) => {
      state.selectedMedicineList = action.payload
    },
    // get form info
    changeCityList: (state, action) => {
      state.cityList = action.payload
    },
    changeDistrictList: (state, action) => {
      state.districtList = action.payload
    },
    changeWardList: (state, action) => {
      state.wardList = action.payload
    },

    changeUploadStatus: (state, action) => {
      state.uploadStatus = action.payload
    },
    changeHasPrescription: (state, action) => {
      state.hasPrescription = action.payload
    },
  },
})

export const selectFileList = (state: RootState) => state[slice.name].fileList
export const selectCityList = (state: RootState) => state[slice.name].cityList
export const selectDistrictList = (state: RootState) => state[slice.name].districtList
export const selectWardList = (state: RootState) => state[slice.name].wardList
export const selectPreList = (state: RootState) => state[slice.name].preList
export const selectMedicineList = (state: RootState) => state[slice.name].medicineList

export const selectSelectedMedicineList = (state: RootState) => state[slice.name].selectedMedicineList
export const selectUploadStatus = (state: RootState) => state[slice.name].uploadStatus
export const selectHasPrescription = (state: RootState) => state[slice.name].hasPrescription

export const {
  changeFileList,
  changeDistrictList,
  changeCityList,
  changeWardList,
  changePreList,
  changeMedicineList,
  changeSelectedMedicineList,
  changeUploadStatus,
  changeHasPrescription,
} = slice.actions

export const PostPrescriptionReducer = slice.reducer
