import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

export const slice = createSlice({
  name: 'userProductScreen',
  initialState: {
    activeTab: 1,
  },
  reducers: {
    changeActiveTab: (state, action) => {
      state.activeTab = action.payload
    },
  },
})

export const selectActiveTab = (state: RootState) => state[slice.name].activeTab

export const { changeActiveTab } = slice.actions

export const UserProductScreenReducer = slice.reducer
