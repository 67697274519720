import { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { PRIVATE_ROUTE, ROUTERS } from '.'
import ProctectedRoute from './ProtectedRoute'

const RenderRoute = () => {
  return (
    <Routes>
      {ROUTERS.map((route, ps) => (
        <Route
          path={route.path}
          element={
            <Suspense fallback={null}>
              <route.element props={route.props} />
            </Suspense>
          }
          key={ps}
        />
      ))}
      <Route element={<ProctectedRoute />}>
        {PRIVATE_ROUTE.map((route, ps) => (
          <Route
            path={route.path}
            element={
              <Suspense fallback={null}>
                <route.element props={route.props} />
              </Suspense>
            }
            key={ps}
          />
        ))}
      </Route>
    </Routes>
  )
}

export default RenderRoute
