import { useDispatch, useSelector } from 'react-redux'
import type { TypedUseSelectorHook } from 'react-redux'
import type { RootState, AppDispatch } from './store'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { getToken } from '../helpers/cookieHelper'
import { KEY_AUTH } from '../constant'
import { getLang } from '../i18n'
import { getTemToken } from '../helpers/storage'
import { useEffect, useRef, useState } from 'react'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

//Use if want to use axios to fetch instead of fetchBaseQuery
export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' },
  ): BaseQueryFn<
    {
      url: string
      method: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    const accessToken = getToken(KEY_AUTH)
    const temToken = getTemToken()
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers: {
          Language: getLang(),
          Authorization: accessToken ? `Bearer ${accessToken}` : '',
          'Temp-Customer-Token': !accessToken && temToken ? temToken : undefined,
        },
      })
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }

//use hook getScrollPosition const { scrollX, scrollY } = useWindowScrollPositions()
export const useWindowScrollPositions = () => {
  const [scrollPosition, setPosition] = useState({ scrollX: 0, scrollY: 0 })

  useEffect(() => {
    function updatePosition() {
      setPosition({ scrollX: window.scrollX, scrollY: window.scrollY })
    }

    window.addEventListener('scroll', updatePosition)
    window.addEventListener('resize', updatePosition)
    updatePosition()

    return () => {
      window.removeEventListener('scroll', updatePosition)
      window.removeEventListener('resize', updatePosition)
    }
  }, [])

  return scrollPosition
}

//hooks get height element
interface ContainerSize {
  width: number
  height: number
}

type UseMeasureArgs = () => {
  ref: React.RefObject<HTMLDivElement>
  size: ContainerSize
  windowSize: ContainerSize
}

const initSize: ContainerSize = { width: 0, height: 0 }

const useMeasure: UseMeasureArgs = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [size, setSize] = useState<ContainerSize>(initSize)
  const [windowSize, setWindowSize] = useState<ContainerSize>(initSize)

  useEffect(() => {
    if (ref.current) {
      setSize({ width: ref.current.offsetWidth, height: ref.current.offsetHeight })
    }
    if (typeof window !== 'undefined') {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
  }, [])

  return { ref, size, windowSize }
}

export default useMeasure
