import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

export const slice = createSlice({
  name: 'prescriptionsPage',
  initialState: {
    currentPage: 1,
  },
  reducers: {
    changeCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
  },
})

export const selectCurrentPage = (state: RootState) => state[slice.name].currentPage

export const { changeCurrentPage } = slice.actions

export const PrescriptionsPageReducer = slice.reducer
