import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

export const slice = createSlice({
  name: 'brandDetailPage',
  initialState: {
    isLoadingBrand: false,
    isLoadingCampaign: false,
  },
  reducers: {
    changeIsLoadingBrand: (state, action) => {
      state.isLoadingBrand = action.payload
    },
    changeIsLoadingCampaign: (state, action) => {
      state.isLoadingBrand = action.payload
    },
  },
})

export const selectBrandDetailPage = (state: RootState) => state[slice.name]
export const { changeIsLoadingBrand, changeIsLoadingCampaign } = slice.actions

export const BrandDetailReducer = slice.reducer
