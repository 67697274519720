import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ROOT, network } from '../../../constant/network'
import { ResponseData } from '../../../types'
import { HomeSearchDto, HomeSearchResult, HomeSearchSuggestType, SearchHistory } from '../../../types/homeSearchType'
import { axiosBaseQuery } from '../../hooks'

const homeSearchApi = createApi({
  reducerPath: 'homeSearchApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    getSuggestSearch: build.query<HomeSearchSuggestType | undefined, unknown>({
      query: () => ({
        url: network.homeSearch.getSuggest,
        method: 'GET',
      }),
      transformResponse: (rawData: ResponseData<HomeSearchSuggestType>) => {
        return rawData.data
      },
    }),
    homeSearch: build.mutation<HomeSearchResult | undefined, unknown>({
      query: (params: HomeSearchDto) => ({
        url: network.homeSearch.homeSearch,
        method: 'POST',
        data: params,
      }),
      transformResponse: (rawData: ResponseData<HomeSearchResult>) => {
        return rawData.data
      },
    }),
    homeSearchHistory: build.query<SearchHistory[] | undefined, unknown>({
      query: () => ({
        url: network.homeSearch.getHistorySearch,
        method: 'GET',
      }),
      transformResponse: (rawData: ResponseData<SearchHistory[]>) => {
        return rawData.data
      },
    }),
  }),
})

export const { useGetSuggestSearchQuery, useHomeSearchHistoryQuery, useHomeSearchMutation } = homeSearchApi

export default homeSearchApi
