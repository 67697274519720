import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../hooks'
import { API_ROOT, network } from '../../../constant/network'
import { ResponseData } from '../../../types'
import { Post } from '../../../types/postType'

export const postApi = createApi({
  reducerPath: 'postAPi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    getPostList: build.query<ResponseData<Post[]>, unknown>({
      query: (data) => ({
        url: network.post.getList,
        method: 'post',
        data: data,
      }),
      transformResponse: (rawResult: ResponseData<Post[]>) => {
        return rawResult
      },
    }),
    getPostDetail: build.query<Post | undefined, string>({
      query: (slug: string | undefined) => ({
        url: network.post.getItem(slug),
        method: 'get',
      }),
      transformResponse: (rawResult: ResponseData<Post>) => {
        const resData = rawResult.data
        return resData
      },
    }),
    getPostPreview: build.query<Post | undefined, unknown>({
      query: (params: any) => ({
        url: network.post.getItem(params.slug),
        method: 'get',
        params: params,
      }),
      transformResponse: (rawResult: ResponseData<Post>) => {
        const resData = rawResult.data
        return resData
      },
    }),
    getProductSuggest: build.mutation<any | undefined, unknown>({
      query: (params: any) => ({
        url: network.post.getProductSuggest,
        method: 'POST',
        data: params,
      }),
      transformResponse: (rawResult: ResponseData<any>) => {
        return rawResult.data
      },
    }),
  }),
})

export const { useGetPostListQuery, useGetPostDetailQuery, useGetPostPreviewQuery, useGetProductSuggestMutation } =
  postApi

export default postApi
